export const BG_PRIMARY = '#F2F2F2';
export const BG_SECONDARY = '#C1C7BB';
export const BG_SECONDARY_LIGHT = '#dca74f';
export const BG_SECONDARY_DARK = '#86550a';
export const BG_SECONDARY_TRANSPARENT = 'rgb(141 50 50 / 30%)';
export const BG_ALTERNATIVE = '#053A28';
export const BG_NAVIGATION = `#376153`;

export const TEXT_PRIMARY_LIGHT = `#fff`;
export const TEXT_PRIMARY = `#1a1a1a`;
export const TEXT_PRIMARY_DARK = `#053A28`;
export const TEXT_SECONDARY = BG_PRIMARY;

// Frame
export const RSVP_BORDER_COLOR = '#053A28'; // RSVP, Wishes
export const WEDDING_BORDER_COLOR = '#F5F6E8'; // Detail
export const GIFT_BORDER_COLOR = '#053A28'; // Gift

export const DEFAULT_BUTTON_PROPS = '';
